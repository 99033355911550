type Position = 'left' | 'right' | 'top' | 'bottom' | 'center' | 'atop-row' | 'bottom-right'

export class ModalClass {
    modalIsClose = true
    element: any
    data: any
    position: Position
    ID = 'id'

    constructor(position: Position = 'center', refreshCallback: {():void} = () => {}) {
        // console.log(position)
        this.position = position
        this.refreshCallback = refreshCallback;
    }

    refreshCallback: {():void}

    show(event: MouseEvent|null, row: any = null) {
        if (row)
            this.data = {id: row[this.ID], row}
        this.element = event?.target || null
        this.modalIsClose = false
    }

    hide() {
        // console.log(this.position)

        this.modalIsClose = true
    }
    hideAndRefresh() {
        this.hide()
        // console.log(this.refreshCallback)
        this.refreshCallback()
    }
}
